import { Dialog, DialogTitle } from "components/Elements";
import React from "react";
import { LoginForm } from "../LoginForm";
import { Lock } from "react-feather";
import { Register } from "./Register";
import { TwoFAForm } from "../TwoFAForm";

export function Login() {
  const [show, setShow] = React.useState(false);

  const [twoFa, setTwoFa] = React.useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <button
        onClick={handleOpen}
        className={`text-green-700 py-2 px-2 flex items-center rounded-sm gap-2`}
      >
        <Lock size={14} className="text-green-700 font-bold cursor-pointer" />
        <h1 className="text-green-700 cursor-pointer">Login / Register</h1>
      </button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            Login to account
          </DialogTitle>
          <>
            {twoFa ? (
              <TwoFAForm onSuccess={handleClose} />
            ) : (
              <LoginForm
                twoFAuth={() => {
                  setTwoFa(true);
                }}
                onSuccess={handleClose}
                registerModal={<Register />}
              />
            )}
          </>
        </div>
      </Dialog>
    </div>
  );
}
