import { useAuth } from "lib/auth";
import React from "react";
import { Home, Lock } from "react-feather";
import { BsCart2 } from "react-icons/bs";
import { MdFavoriteBorder, MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import { useCategories } from "../api/getCategories";

export const MobileSidebar = ({ page }) => {
  const auth = useAuth();

  const categoriesQuery = useCategories();

  return (
    <div className="">
      <div className="mx-4 my-2 w-full space-y-4">
        <div className="my-2 md:hidden flex">
          <p className="text-gray-700 py-2 px-2 text-sm flex items-center rounded-sm gap-2">
            <Link to="/" className="hover:text-white flex space-x-2">
              <Home />
              <span>Home</span>
            </Link>
          </p>
        </div>

        {auth.user ? (
          <>
            <div>
              <div className="flex justify-left text-sm px-2 py-1 items-center">
                <div>
                  <div className="dropdown relative inline-block text-left">
                    <button
                      className="inline-flex w-full text-gray-700 rounded-md border-none py-2 text-md font-medium text-gray-700  hover:bg-gray-50 focus:outline-none"
                      type="button"
                      id="menuDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="flex items-center mr-2 justify-center h-6 w-6 rounded-full bg-green-500 text-white text-xs font-medium">
                        {auth?.user?.name && auth.user?.name[0]}
                      </div>
                      {auth.user?.name}
                      <MdKeyboardArrowDown className="-mr-1 ml-2 h-5 w-5" />
                    </button>
                    <ul
                      className="dropdown-menu min-w-max absolute hidden bg-white text-md z-50 float-left py-2 list-none text-left 
                      rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                      aria-labelledby="menuDropdown"
                    >
                      <li>
                        <Link
                          to="/profile"
                          className="dropdown-item text-gray-900 block px-4 py-2 text-md"
                        >
                          My Account
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/wishlist"
                          className="dropdown-item text-gray-900 block px-4 py-2 text-md"
                        >
                          Wishlist
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/profile/orders"
                          className="dropdown-item text-gray-900 block px-4 py-2 text-md"
                        >
                          Orders
                        </Link>
                      </li>
                      <li onClick={() => auth.logout()}>
                        <Link
                          to="/"
                          className="dropdown-item text-gray-900 block px-4 py-2 text-md"
                        >
                          Sign out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <Link to="/wishlist" className={page === "wishlist" ? "" : ""}>
              <div className="text-sm text-gray-700 py-2 px-2 text-md flex items-center rounded-sm gap-2">
                <MdFavoriteBorder className="text-gray-700  text-xl font-bold cursor-pointer" />
                <h1 className="text-gray-700 cursor-pointer">Wishlist</h1>
              </div>
            </Link>
            <Link to="/cart" className={page === "cart" ? "" : ""}>
              <div>
                <div className="text-sm text-gray-700 py-2 px-2 text-md flex items-center rounded-sm gap-2">
                  <BsCart2 className="text-gray-700 font-bold  text-xl cursor-pointer" />
                  <h1 className="text-gray-700 cursor-pointer">My Cart</h1>
                </div>
              </div>
            </Link>
          </>
        ) : (
          <Link to="/auth/login" className={page === "login" ? "" : ""}>
            <div className="flex lg:hidden text-gray-700 py-2 px-2 text-sm flex items-center rounded-sm gap-2">
              <Lock className="text-gray-700 font-bold cursor-pointer" />
              <h1 className="text-gray-700 cursor-pointer">Login / Register</h1>
            </div>
          </Link>
        )}

        <div className="text-gray-700 text-sm flex flex-col rounded-sm gap-2">
          <div className="mx-3 my-2 space-y-4">
            <Link to="/services/bids">
              <p className="my-2">Bids</p>
            </Link>
            <Link to="/services/book-evaluation">
              <p className="my-2">Book Evaluation</p>
            </Link>
            {categoriesQuery?.data?.map((category, index) => {
              return (
                <div key={index}>
                  <Link to={`/categories/${category.slug}`}>
                    <p>{category.name}</p>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
